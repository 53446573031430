import { isEmpty } from 'lodash'
import { Steps } from 'pages/AddPendingVisitPage/AddPendingVisitPage'
import queryString from 'query-string'
export const PAGE_NOT_FOUND = '/404'
/** AuthBranch branch */
export const AUTH = '/auth'
/** AuthBranch routes */
export const LOGIN = '/auth/login'
export const FORBIDDEN = '/auth/forbidden'
export const UNDERAGE = '/auth/underage'
export const FORGOT_PASSWORD = '/auth/forgot/:resetToken'
export const WEB_SSO = '/web/auth/tokens'
export const SSO_REDIRECT = '/auth/sso'
export const MOBILE_SSO = '/auth/tokens'

export const TERMS_CONDITIONS = '/registration/terms-conditions'
export const REGISTRATION = '/registration/:memberId'
export const createRegistrationPath = (path: string, memberId: string, params?: object) => {
  const route = path.replace(':memberId', memberId)
  if (isEmpty(params)) return route
  const query = queryString.stringify(params)
  return `${route}?${query}`
}

/** Migration Verification route */
export const MIGRATE_VERIFY = '/migrate/verify-email'

/** Graduation route */
export const GRADUATION = '/graduation'

/** Unsubscribe email route */
export const EMAIL_UNSUBSCRIBE = '/unsubscribe/:uniqueId'

/** Terms and Conditions routes */
export const TERMS_AND_CONDITIONS = '/terms-and-conditions'

/** Dashboard routes */
export const DASHBOARD = '/'

/** Success routes */

export const SUCCESS = '/success'

/** Visit routes */
export const VISITS = '/visits'
export const PENDING_VISITS = '/visits/pending'
export const ADD_PENDING_VISIT = '/visits/pending/add'
export const createAddPendingVisitPath = (path: string, params?: any) => {
  const url = path
  if (params) {
    return `${url}${params}`
  }
  return url
}
export const PENDING_VISIT_VIEW = '/visits/pending/:pendingVisitId'
export const createPendingVisitPath = (path: string, pendingVisitId: string) =>
  path.replace(':pendingVisitId', pendingVisitId)
export const VISIT_VIEW = '/visits/:visitId'
export const VISIT_VIDEO_VIEW = '/:memberId/visits/:pendingVisitId/video'
export const VISIT_CHAT_VIEW = '/:memberId/visits/:pendingVisitId/chat'
export const PUBLIC_VIDEO_VISIT = '/video/participant'
export const VISIT_FEEDBACK = '/:memberId/visits/:pendingVisitId/feedback'
export const VISIT_RATING_VIEW = '/visits/:pendingVisitId/rating'
export const createVisitPath = (path: string, pendingVisitId: string, memberId: string) =>
  path.replace(':pendingVisitId', pendingVisitId).replace(':memberId', memberId)

/** Message routes */
export const MESSAGES = '/messages'
export const VISIT_MESSAGES = '/messages/visit'
export const TASK_MESSAGES = '/messages/task'
export const VISIT_MESSAGE_VIEW = '/messages/visit/:messageId'
export const TASK_MESSAGE_VIEW = '/messages/task/:messageId'
export const createMessagePath = (path: string, messageId: number | string) =>
  path.replace(':messageId', '' + messageId)

/** Explore routes */
export const EXPLORE = '/explore'
export const EXPLORE_COMPONENT = '/explore/component/:componentId'
export const EXPLORE_CATEGORY = '/explore/category/:categoryId'
export const EXPLORE_JOURNEY = '/explore/journey/:journeyId'
export const createExploreComponentPath = (path: string, componentId: string) =>
  path.replace(':componentId', componentId)
export const createExploreCategoryPath = (path: string, categoryId: string) => path.replace(':categoryId', categoryId)
export const createExploreJourneyPath = (path: string, journeyId: string) => path.replace(':journeyId', journeyId)
export const createExplorePath = (path: string, params?: any) => {
  const url = path
  if (params) {
    return `${url}${params}`
  }
  return url
}

/** Medical Info routes */
export const MEDICAL = '/medical' //-- branch name
export const createMedicalPath = (path: string, memberId: string, params?: any) => {
  const url = path.replace(':memberId', memberId)
  if (params) {
    return `${url}?${params.queryParams}`
  }
  return url
}
export const createPrimaryCarePath = (path: string, memberId: string, providerId = '') => {
  return path.replace(':memberId', memberId).replace('/:providerId', `/${providerId}`)
}
export const createAllergyPath = (path: string, memberId: string, allergyId = '') => {
  return path.replace(':memberId', memberId).replace('/:allergyId', `/${allergyId}`)
}
export const createMedicationPath = (path: string, memberId: string, medicationId = '') => {
  return path.replace(':memberId', memberId).replace('/:medicationId', `/${medicationId}`)
}

export const MEDICAL_WIZARD = '/visits/pending/add/medical-wizard/:memberId/history'
export const MEDICAL_MEMBER = '/medical/:memberId'
export const MEDICAL_OVERVIEW = '/medical/:memberId/overview'
export const MEDICAL_GENERAL = '/medical/:memberId/general'
export const MEDICAL_LIFESTYLE = '/medical/:memberId/lifestyle'
export const MEDICAL_CONDITIONS = '/medical/:memberId/conditions'
export const MEDICAL_FAMILY = '/medical/:memberId/family'
export const MEDICAL_MEDICATIONS = '/medical/:memberId/medications'
export const MEDICAL_MEDICATION = '/medical/:memberId/medications/:medicationId'
export const MEDICAL_ALLERGIES = '/medical/:memberId/allergies'
export const MEDICAL_ALLERGY = '/medical/:memberId/allergies/:allergyId'
export const MEDICAL_PROVIDERS = '/medical/:memberId/providers'
export const MEDICAL_PROVIDER = '/medical/:memberId/providers/:providerId'
export const MEDICAL_PHOTOS = '/medical/:memberId/photos'

/** Account routes */
export const ACCOUNT = '/account'
export const ACCOUNT_GENERAL = '/account/general'
export const ACCOUNT_DEPENDENTS = '/account/dependents'
export const ACCOUNT_DEPENDENTS_ADD = '/account/dependents/add'
export const ACCOUNT_DEPENDENT_VIEW = '/account/dependents/:dependentId'
export const createDependentPath = (path: string, dependentId: string) => path.replace(':dependentId', dependentId)
export const ACCOUNT_SERVICES = '/account/services'
export const ACCOUNT_HISTORY = '/account/history'
export const ACCOUNT_SECURITY = '/account/security'
export const ACCOUNT_BILLING = '/account/billing'
export const ACCOUNT_MEDICAL = '/account/medical'

/** Help Center routes */
export const HELP_CENTER = '/help'

/** Send Feedback routes */
export const SEND_FEEDBACK = '/feedback'

/** Community routes */
export const COMMUNITY = '/community'
export const createCommunityPostPath = (path: string, postId: string) => path.replace(':postId', postId)
export const COMMUNITY_POST = '/community/:postId'

/* NPS Survey route */
export const SURVEYS = '/surveys'

/* Accessibility documentation */
export const ACCESSIBILITY_DOC = '/accessibility/documentation'

/* Mobile Paths */
export const PAYMENT_METHOD_MOBILE = '/add-payment-method/:memberId'

export const createWithQuery = (path: string, params: object) => {
  const query = queryString.stringify(params)
  return `${path}?${query}`
}

export const createInitialVisitPath = (hasClinicalService: boolean, isServiceLineRoutingFlagOn: boolean) => {
  if (isServiceLineRoutingFlagOn) {
    return createWithQuery(ADD_PENDING_VISIT, {
      step: Steps.routingEntry,
    })
  }
  return createWithQuery(ADD_PENDING_VISIT, {
    step: hasClinicalService ? Steps.memberSelect : Steps.addService,
  })
}

/** Breadcrumbs routes */
export const CRUMB_DASHBOARD = 'Home'
export const CRUMB_SEND_FEEDBACK = 'Feedback'
export const CRUMB_MESSAGES = 'Messages'
export const CRUMB_ADD_PENDING_VISIT = 'Get Care'
export const CRUMB_PENDING_VISITS = 'Get Care'
export const CRUMB_MEDICAL_MEMBER = 'Account / Medical'
export const CRUMB_MEDICAL_LIFESTYLE = 'Lifestyle'
export const CRUMB_MEDICAL_GENERAL = 'General'
export const CRUMB_MEDICAL_MEDICATIONS = 'Medications'
export const CRUMB_MEDICAL_ALLERGIES = 'Allergies'
export const CRUMB_MEDICAL_PROVIDERS = 'Providers'
export const CRUMB_MEDICAL_CONDITIONS = 'Medical History'
export const CRUMB_MEDICAL_FAMILY = 'Family'
export const CRUMB_MEDICAL_OVERVIEW = 'Overview'
export const CRUMB_COMMUNITY = 'Community'
export const CRUMB_HELP_CENTER = 'Help Center'
export const CRUMB_EXPLORE = 'Self-Care'
export const CRUMB_ACCOUNT_PASSWORD = 'Change Password'
export const CRUMB_ACCOUNT_DEPENDENTS = 'Dependents'
export const CRUMB_ACCOUNT_DEPENDENTS_ADD = 'Add New'
export const CRUMB_ACCOUNT_GENERAL = 'General Information'
export const CRUMB_ACCOUNT = 'Account'
export const CRUMB_ACCOUNT_SERVICES = 'Services'
export const CRUMB_ACCOUNT_BILLING = 'Billing'
export const CRUMB_ACCOUNT_MEDICAL = 'Medical'
export const CRUMB_VISIT_FEEDBACK = 'Feedback'
export const CRUMB_VISIT_CHAT_VIEW = 'Chat'
export const CRUMB_VISIT_VIDEO_VIEW = 'Video'
export const CRUMB_PAGE_NOT_FOUND = '404'
export const CRUMB_MEDICAL_WIZARD = 'Get Care'
export const CRUMB_HEALTH_PROFILE = 'Health Profile'

export const CRUMB_GRADUATION = 'Graduation'
export const CRUMB_MIGRATE_VERIFY = 'Verify email'
export const CRUMB_TERMS_CONDITIONS = 'Terms conditions'
export const CRUMB_SURVEYS = 'Surveys'
export const CRUMB_SUCCESS = 'Success Coaching'

/** Clinical Only routes - These routes are restricted for Digital Only members */
export const CLINICAL_ONLY_PATHS = [
  MESSAGES,
  VISITS,
  MEDICAL,
  ACCOUNT_MEDICAL,
  ACCOUNT_DEPENDENTS,
  ACCOUNT_SERVICES,
  ACCOUNT_BILLING,
]
