import ApiFactory, { ApiBlobFactory, ApiTypes } from '../api'
import * as ApiThunks from '../redux/thunks/api'
import { DASHBOARD } from '../routes/paths'
import * as SessionKeys from './session-constants'
import { getFromStorage, sessionPersistence } from 'util/storage'
import moment from 'moment-timezone'
import { normalizeJsonFromApi } from '../api/helpers'
import jwtDecode from 'jwt-decode'
import * as APM from 'util/apm'

const TRACK_STATUSES = [500, 422, 504]

const isTokenExpired = () => {
  const expirationDate = getFromStorage(SessionKeys.TOKEN_EXPIRES_AT)
  if (!expirationDate && !getFromStorage(SessionKeys.TOKEN)) {
    //  Some calls happen before a token is issued
    return false
  }
  const now = moment()
  try {
    const exp = moment(expirationDate)
    return now.isSameOrAfter(exp)
  } catch (e) {
    console.error(e)
    return true
  }
}

const deepestData = (response = {}) => {
  let data = response.data
  while (data?.data) {
    data = data.data
  }
  return data
}

const refreshRegEx = new RegExp(/(refresh)/)
const loginRegEx = new RegExp(/(login)/)
const updatePasswordRegEx = new RegExp(/(\/v1\/password\/update)/)
const logoutRegEx = new RegExp(/(logout)/)
const addPaymentMethodMobileRegEx = new RegExp(/(signature)/)

const onRequestFulfilled = async (request) => {
  let token = sessionPersistence.get(SessionKeys.TOKEN)
  if (!refreshRegEx.test(request.url) && isTokenExpired()) {
    console.error('Token is expired, attempting refresh')
    const AuthApi = ApiFactory.getApi(ApiTypes.auth)
    const result = await AuthApi.post('/v1/refresh', { refresh_token: getFromStorage(SessionKeys.REFRESH_TOKEN) })
    const data = normalizeJsonFromApi(deepestData(result))
    if (data?.token) {
      const decoded = jwtDecode(data?.token)
      const createdAt = moment(decoded.iat * 1000).toISOString()
      const expiresAt = moment(decoded.exp * 1000).toISOString()

      sessionPersistence.set(SessionKeys.REFRESH_TOKEN, data?.refreshToken)
      sessionPersistence.set(SessionKeys.TOKEN, data?.token)
      sessionPersistence.set(SessionKeys.TOKEN_EXPIRES_AT, expiresAt)
      sessionPersistence.set(SessionKeys.TOKEN_CREATED_AT, createdAt)
      sessionPersistence.set(SessionKeys.USER_ID, data?.userId)
    }
    token = data?.token
  }
  if (token) request.headers.Authorization = `Bearer ${token}`
  return request
}

// TODO this is never called
const onRequestError = (store, history) => (error) => {
  console.log('requestInterceptor error')
  console.error(error)
  const { response } = error
  if (response?.data?.errors?.length) {
    const [firstError] = response.data.errors
    if (!!firstError && firstError.status === '401') {
      console.log({ firstError })
      history.push('/')
    }
  }
}

const onResponseFulfilled = (response) => {
  // console.log(response);
  return response
}

async function logout(store, history) {
  const { dispatch } = store
  await dispatch(ApiThunks.logout(history))
  history.push(DASHBOARD)
}

const onResponseError = (store, history) => async (error) => {
  console.log('responseInterceptor error', { error })
  console.error(error)
  const { response } = error
  console.log(response && response.status)
  console.log(response)
  if (TRACK_STATUSES.includes(response?.status)) {
    APM.captureException(error)
  }
  if (response?.status === 401) {
    console.error('Error 401 received from API')
    // no need to log out if it's a login attempt, an update password attempt, or a logout attempt
    // no need to redirect to login if the page is PaymentMethodMobile
    if (
      !loginRegEx.test(response?.config?.url) &&
      !updatePasswordRegEx.test(response?.config?.url) &&
      !logoutRegEx.test(response?.config?.url) &&
      !addPaymentMethodMobileRegEx.test(response?.config?.url)
    ) {
      await logout(store, history)
    }
  } else if (response?.data?.errors?.length) {
    const [firstError] = response.data.errors
    if (!!firstError && firstError.status === '401') {
      console.log({ firstError })
      await logout(store, history)
    }
  }
  if (error.response) return error.response
  return error
}

const Interceptors = {
  setupInterceptors: (store, history) => {
    const onReqError = onRequestError(store, history)
    const onResError = onResponseError(store, history)

    const apiKeys = Object.values(ApiTypes)
    apiKeys.forEach((name) => {
      const api = ApiFactory.getApi(name)
      api.interceptors.request.use(onRequestFulfilled, onReqError)
      api.interceptors.response.use(onResponseFulfilled, onResError)
    })

    const blobApi = ApiBlobFactory.getApi('member')
    blobApi.interceptors.request.use(onRequestFulfilled, onReqError)
    blobApi.interceptors.response.use(onResponseFulfilled, onResError)
  },
}

export default Interceptors
