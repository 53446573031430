import * as Sentry from '@sentry/react'
import Config from 'config.ts'

export const initialize = () => {
  Sentry.init({
    dsn: Config.sentryDSN,
    environment: Config.environment,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    denyUrls: [/^(http|https):\/\/localhost:.+/g],
    tracesSampleRate: Config.isProduction ? 0.1 : 0.5,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: Config.isProduction ? 0.75 : 0.1,
    // TODO: n is not a function. (In 'n()', 'n' is undefined) error might be able to be removed once node libraries are updated
    ignoreErrors: [
      "TypeError: n is not a function. (In 'n()', 'n' is undefined)",
      "n is not a function. (In 'n()', 'n' is undefined)",
    ],
  })
}

export const captureEvent = (error: Sentry.Event) => {
  Sentry.captureEvent(error)
}

export const captureException = (error: Error) => {
  Sentry.captureException(error)
}

export const captureMessage = (message: string) => {
  Sentry.captureMessage(message)
}

export const setUser = (user: Sentry.User | null) => {
  Sentry.setUser(user)
}
