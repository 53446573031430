import * as Yup from 'yup'
import { getMedicationDateTimestamp } from '../util/medication'

export const MedicalBasicInfoSchema = Yup.object().shape({
  heightFt: Yup.number().label('Feet').required('Height in Feet cannot be empty'),
  heightIn: Yup.number()
    .label('Inches')
    .when('heightFt', {
      is: (value) => !value || value === 0,
      then: Yup.number().min(6, 'Height must be at least 6 inches'),
      otherwise: Yup.number(),
    })
    .required('Height in Inches cannot be empty'),
  weightLbs: Yup.number()
    .label('Weight in pounds')
    .min(1, 'Weight in pounds must be greater than 1 lbs')
    .max(1000, 'Weight in pounds must be less than or equal to 1000 lbs')
    .required('Weight in pounds cannot be empty'),
})

const drinkFrequency = ['Sometimes', 'Often', 'Rarely']
export const LifestyleSchema = (required = true) => {
  let validation = Yup.boolean().nullable()
  if (required) {
    validation = validation.required()
  }
  return Yup.object().shape({
    smoke: validation.label("'Do you smoke/use tobacco?'"),
    vape: validation.label("'Do you vape?'"),
    drink: validation.label("'Do you drink alcohol?'"),
    drinkFrequency: Yup.string()
      .label("'How often do you drink?'")
      .when('drink', {
        is: (value) => value === true,
        then: Yup.string().required().oneOf(drinkFrequency),
        otherwise: Yup.string()
          .oneOf(drinkFrequency.concat([null]))
          .nullable()
          .strip(),
      }),
    sexuallyActive: validation.label("'Are you sexually active?'"),
  })
}

export const ConditionSchema = Yup.object().shape({
  condition: Yup.string().required(),
  conditionId: Yup.string().required(),
  id: Yup.string(),
  name: Yup.string().required(),
  value: Yup.boolean(),
})

export const OtherConditionSchema = Yup.object().shape({
  id: Yup.string().notRequired(),
  conditionId: Yup.string().notRequired(),
  value: Yup.string().required(),
})

export const medicationsRequiredFields = [
  'Medication Name',
  'Dispense Unit',
  'Start Date',
  'End Date',
  'Directions',
  'Status',
]

export const medicationStatusesData = {
  Current: {
    value: 'Current',
  },
  Discontinued: {
    value: 'Discontinued',
  },
  Error: {
    value: 'Error',
    isHidden: true,
  },
} as const

export const medicationStatusesMap = Object.values(medicationStatusesData)
  .map(({ value }) => value)
  .reduce<Partial<Record<keyof typeof medicationStatusesData, string>>>((acc, curr) => ({ ...acc, [curr]: curr }), {})

const allMedicationStatuses = Object.values(medicationStatusesMap)

export const medicationStatusOptions = allMedicationStatuses.filter((v) => !medicationStatusesData[v]?.isHidden)

export const medicationDiscontinuedReasons = [
  'Adverse drug reactions',
  'No longer indicated',
  'Contraindication',
  'Condition improvement',
  'Safety concerns',
  'Reducing polypharmacy',
  'Treatment goals',
]

export const MedicationSchema = Yup.object({
  name: Yup.string().label('Medication Name').min(3).required(),
  strengthAndUnit: Yup.string().label('Strength').notRequired().nullable(),
  unit: Yup.string().label('Dispense Unit').required(),
  startDate: Yup.string().label('Start Date').notRequired().nullable().default(null),
  endDate: Yup.string()
    .label('End Date')
    .notRequired()
    .nullable()
    .default(null)
    .test('minEndDate', 'End Date must be later than Start Date', function (endDate) {
      const { startDate } = this.parent

      const startDateTimestamp = getMedicationDateTimestamp(startDate)
      const endDateTimestamp = getMedicationDateTimestamp(endDate)
      return !startDateTimestamp || !endDateTimestamp || endDateTimestamp >= startDateTimestamp
    }),
  direction: Yup.string()
    .max(500, 'Directions must be at most 500 characters')
    .label('Directions')
    .notRequired()
    .nullable()
    .default(null),
  status: Yup.string().label('Status').oneOf(allMedicationStatuses).required(),
  discontinuedReason: Yup.string().notRequired().nullable(),
  dispensableDrugId: Yup.string().notRequired().nullable().default(null),
  lastUpdatedBy: Yup.string().notRequired().nullable().default(null),
  lastUpdatedByType: Yup.string().oneOf(['member']).required().default('member'),
})

export const MedicationSchemaWithDiscontinuedReason = MedicationSchema.concat(
  Yup.object({
    discontinuedReason: Yup.string()
      .label('Discontinued Reasoning')
      .when('status', {
        is: (status) => status === medicationStatusesMap.Discontinued,
        then: (schema) => schema.oneOf(medicationDiscontinuedReasons).required(),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
  }),
)

const MedicalHistorySchema = Yup.object().shape({
  memberId: Yup.string().required(),
  heightFt: Yup.number(),
  heightIn: Yup.number(),
  weightLbs: Yup.number(),
  // bpSystolic: Yup.number()
  //   .notRequired()
  //   .default(null),
  // bpDiastolic: Yup.number()
  //   .notRequired()
  //   .default(null),
  lifestyle: LifestyleSchema(false),
  conditions: Yup.array().of(ConditionSchema).default([]),
  other: Yup.array().of(OtherConditionSchema).default([]),
  medications: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
      }),
    )
    .default([]),
  allergiesCompleted: Yup.boolean().default(false),
  medicationsCompleted: Yup.boolean().default(false),
  conditionsCompleted: Yup.boolean().default(false),
  familyHistoryCompleted: Yup.boolean().default(false),
  primaryCareCompleted: Yup.boolean().default(false),
})

export default MedicalHistorySchema
