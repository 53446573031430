import React from 'react'

type SuccessSectionCardProps = {
  title: string
  description: string
  buttonLabel: string
  onButtonClick: () => void
}

const SuccessSectionCard: React.FunctionComponent<SuccessSectionCardProps> = ({
  title,
  description,
  buttonLabel,
  onButtonClick,
}) => {
  return (
    <div className="rounded-2xl border bg-white p-8 md:p-10">
      <h2 className="typography-body-xl md:typography-body-xxl mb-4 font-semibold">{title}</h2>
      <p className="typography-body-l font-normal">{description}</p>
      <button className="btn btn-neutral-outlined mt-6 w-full" type="button" onClick={onButtonClick}>
        {buttonLabel}
      </button>
    </div>
  )
}

export default SuccessSectionCard
